import React, { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import TWEEN from '@tweenjs/tween.js';

const InteractiveRubiksCube = () => {
  const mountRef = useRef(null);
  const [cubies, setCubies] = useState([]); // Track all cubies
  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();
  const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
  camera.position.set(0, 0, 5);

  useEffect(() => {
    const scene = new THREE.Scene();
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    const cubeGroup = new THREE.Group();
    scene.add(cubeGroup);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(0, 1, 1);
    scene.add(directionalLight);

    new OrbitControls(camera, renderer.domElement);

    const cubeSize = 0.9;
    const geometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize);
    const material = new THREE.MeshBasicMaterial({
      color: 0x00ff00,
      transparent: true,
      opacity: 0.5,
    });
    const edgesMaterial = new THREE.LineBasicMaterial({ color: 0x000000 });

    const createdCubies = [];
    for (let x = 0; x < 3; x++) {
      for (let y = 0; y < 3; y++) {
        for (let z = 0; z < 3; z++) {
          if (x === 1 && y === 1 && z === 1) continue; // Skip the center cube
          const cubeMesh = new THREE.Mesh(geometry, material);
          cubeMesh.position.set(x - 1, y - 1, z - 1);
          cubeMesh.userData.originalPosition = cubeMesh.position.clone();

          // Create an outline for each cube
          const edges = new THREE.EdgesGeometry(geometry);
          const outline = new THREE.LineSegments(edges, edgesMaterial);
          cubeMesh.add(outline);

          cubeGroup.add(cubeMesh);
          createdCubies.push(cubeMesh);
        }
      }
    }
    setCubies(createdCubies);

    const extrudeCube = (clickedCubie, direction, extrusionDistance) => {
      const endPosition = clickedCubie.userData.originalPosition.clone().addScaledVector(direction, extrusionDistance);
      new TWEEN.Tween(clickedCubie.position)
        .to(endPosition, 500)
        .easing(TWEEN.Easing.Quadratic.Out)
        .start();
      clickedCubie.userData.isMoved = true;
    };

    const onCanvasClick = (event) => {
  mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
  mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;
  
  raycaster.setFromCamera(mouse, camera);
  const intersects = raycaster.intersectObjects(createdCubies, false);


  if (intersects.length > 0) {
    const clickedCubie = intersects[0].object;
    const extrusionDistance = cubeSize * 0.5;

    if (!clickedCubie.userData.originalPosition) {
      clickedCubie.userData.originalPosition = clickedCubie.position.clone();
      clickedCubie.userData.isMoved = false;
    }

    if (clickedCubie.userData.isMoved) {
      new TWEEN.Tween(clickedCubie.position)
        .to(clickedCubie.userData.originalPosition, 500)
        .easing(TWEEN.Easing.Quadratic.Out)
        .start();
      clickedCubie.userData.isMoved = false;
    } else {
      const isMiddleEdgeCube = (clickedCubie.position.x === 0 && Math.abs(clickedCubie.position.y) === 1 && Math.abs(clickedCubie.position.z) === 1) ||
                               (Math.abs(clickedCubie.position.x) === 1 && clickedCubie.position.y === 0 && Math.abs(clickedCubie.position.z) === 1) ||
                               (Math.abs(clickedCubie.position.x) === 1 && Math.abs(clickedCubie.position.y) === 1 && clickedCubie.position.z === 0);

      let extrusionVector = new THREE.Vector3(0, 0, 0);
      if (isMiddleEdgeCube) {
        if (clickedCubie.position.x === 0) extrusionVector.setY(extrusionDistance * Math.sign(clickedCubie.position.y));
        else if (clickedCubie.position.y === 0) extrusionVector.setZ(extrusionDistance * Math.sign(clickedCubie.position.z));
        else if (clickedCubie.position.z === 0) extrusionVector.setX(extrusionDistance * Math.sign(clickedCubie.position.x));
      } else {
        // Original logic for other cubes
        if (Math.abs(clickedCubie.position.x) > Math.abs(clickedCubie.position.y) && Math.abs(clickedCubie.position.x) > Math.abs(clickedCubie.position.z)) {
          extrusionVector.setX(extrusionDistance * Math.sign(clickedCubie.position.x));
        } else if (Math.abs(clickedCubie.position.y) > Math.abs(clickedCubie.position.x) && Math.abs(clickedCubie.position.y) > Math.abs(clickedCubie.position.z)) {
          extrusionVector.setY(extrusionDistance * Math.sign(clickedCubie.position.y));
        } else {
          extrusionVector.setZ(extrusionDistance * Math.sign(clickedCubie.position.z));
        }
      }

      new TWEEN.Tween(clickedCubie.position)
        .to({ 
          x: clickedCubie.userData.originalPosition.x + extrusionVector.x,
          y: clickedCubie.userData.originalPosition.y + extrusionVector.y,
          z: clickedCubie.userData.originalPosition.z + extrusionVector.z
        }, 500)
        .easing(TWEEN.Easing.Quadratic.Out)
        .start();
      clickedCubie.userData.isMoved = true;
    }
  }
};

    renderer.domElement.addEventListener('mousedown', onCanvasClick);

    const animate = () => {
      requestAnimationFrame(animate);
      TWEEN.update();
      renderer.render(scene, camera);
    };

    animate();

    return () => {
      renderer.domElement.removeEventListener('mousedown', onCanvasClick);
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  // Rest of your component including onCanvasClick and other functions

  // ...

  return <div ref={mountRef} style={{ height: '100vh', width: '100vw' }} />;
};

export default InteractiveRubiksCube;
